export class SearchParams {
  restaurant_name: string;
  restaurant_type: string;
  township: string;
  opening_time: string;

  // constructor(name: string, type: string, tsp: string, times: string) {
  //   this.restaurant_name = name;
  //   this.restaurant_type = type;
  //   this.township = tsp;
  //   this.opening_time = times;
  // }
}
