import { Component, OnInit } from '@angular/core';
import { LoginService} from '../../../../services/login.service'
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

/**
 * Reset Password Component
 *
 * @export
 * @class ResetpasswordComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  //verification code
  verification_code = null;
  //url id for parameter
  url_id: string;
  //password to reset
  new_password: any;
  //confirm password to reset
  confirmed_password: any;
  submitted: boolean = false;
  errorMessage: string;

  /**
   * Constructor()
   *
   */
  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private route: ActivatedRoute,
    private router: Router
  ) { 
    this.resetPasswordForm = this.fb.group({
      code: ['', [Validators.required]],
      new_password: ['', [Validators.required]],
      confirmed_password: ['', [Validators.required]]
    });
  }

  /**
   * ngOnInit()
   */
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.url_id = params['urlId'];
    });
  }

  get resetPwdValidator() {    
    return this.resetPasswordForm.controls;
  }

  getResetPassword() {
    let values = this.resetPasswordForm.value;
    this.resetPassword(values);
  }
  /**
   * resetPassword
   *
   * Reset password for user
   */
  resetPassword(values) {
    //check verifcation code for null
    this.submitted = true;
    //check password and confirm password
    if (values.new_password !== values.confirmed_password) {
      return this.errorMessage = "New password and confirmed password must be same!";
    }
    if (this.resetPasswordForm.invalid) {
      return false;
    }
    //reset password for user
    this.loginService.resetPassword(values.code, this.url_id, values.new_password)
      .then((res: any) => {
        //resetting password is success        
        if (res.status == 200) {
          this.router.navigate(['login']);
          return;
        }
        //verification code is wrong
          this.errorMessage = res.error;
          return;
      });
  }
}
