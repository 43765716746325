export class User {
  id: number;
  name: string;
  name_ucode: string;
  password: string;
  facebook_id: string;
  phone_no: string;
  mail_address: string;
  date_of_birth: string;
  gender: number;
  profileimg_url: string;
  token: string;
  image_path: string;
}