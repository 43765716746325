export class Restaurant {
  id: number;
  township_id: number;
  city_id: number;
  name: string;
  name_ucode: string;
  description: string;
  description_ucode: string;
  contact_email: string;
  average_grade: number;
  price_range: number;
  facebook_page: string;
  website_url: string;
  address: string;
  phone_no: number;
  class_type: number;
  owner_id: number;
  delete_flag: number;
  created_user_id: number;
}
