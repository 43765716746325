import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Pagination } from '../../../models';
import { PaginatorService } from '../../../services';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit, OnChanges{
  @Input() pagination: Pagination
  pages: any[];

  constructor(
    private paginator: PaginatorService
  ) { }

  ngOnInit() {
    this.build();
  }

  ngOnChanges() {
    this.build();
  }

  build(): void {
    let pg = this.paginator.build(this.pagination);
    this.paginator = pg.paginator;
    this.pages = pg.pages;
  }

  hasPages(): boolean {
    return this.paginator.hasPages();
  }

  onFirstPage(): boolean {
    return this.pagination.currentPage === 1;
  }

  hasMorePages(): boolean {
    return this.pagination.lastPage > this.pagination.currentPage;
  }

  isCurrentPage(page: number): boolean {
    return this.pagination.currentPage === page;
  }

  getPrevParams(): object {
    return this.paginator.getPrevParams();
  }

  getNextParams(): object {
    return this.paginator.getNextParams();
  }

  isString(value: any): boolean {
    return typeof value === 'string' || value instanceof String;
  }

  isArray(value: any): boolean {
    return Array.isArray(value);
  }
}
