import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User, Password } from '../../../models';
import { UserService, AuthService } from '../../../services';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.css']
})
export class PasswordChangeComponent implements OnInit {
  loggedInUser: User;
  passwordForm: FormGroup;
  user: User;
  isSubmitted: boolean = false;
  changePW: boolean;
  password: Password;
  invalid_message: boolean = false;
  success_message: boolean = false;

  @Output() pwChangeEvent = new EventEmitter<boolean>();
  @Output() successEvent = new EventEmitter<boolean>();

  get pwForm() {
    return this.passwordForm.controls;
  }
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private authService: AuthService
  ) {
      this.loggedInUser = this.authService.getUser();
      this.passwordForm = this.fb.group({
        oldPassword: ['', [Validators.required]],
        newPassword: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
        id: [this.loggedInUser.id]
      });
   }

  ngOnInit() {
  }

  changePassword() {
    this.isSubmitted = true;
    let password = this.passwordForm.value;
    if (this.passwordForm.invalid) {
      return;
    }
    this.changeUserPassword(password);
  }

  changeUserPassword(password: Password) {
    this.userService.changeUserPassword(password).subscribe(
      data => {
        if (data.result == 1) {
          this.isSubmitted = true;
          this.success_message = true;
          this.invalid_message = false;
          this.router.navigate(['/profile']);
        } else {
          this.isSubmitted = false;
          this.invalid_message = true;
          this.success_message = false;
        }
      }
    );
  }

  hidePWChange() {
    this.changePW = false;
    this.pwChangeEvent.emit(this.changePW);
    this.router.navigate(['profile']);
  }
}
