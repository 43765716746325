import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  TOKEN_KEY: string = "AUTH_TOKEN";
  FB_TOKEN_KEY: string = "FB_AUTH_TOKEN";

  private TOKEN = {
    get: (key: string) => {
      let token = sessionStorage.getItem(key);
      if (!token) {
        token = localStorage.getItem(key);
      }
      return token;
    },
    save: (token: string, isRemember: boolean, key: string) => {
      if (isRemember) {
        sessionStorage.setItem(key, token);
      }
      localStorage.setItem(key, token);
    },
    remove: (key: string) => {
      localStorage.removeItem(key);
      sessionStorage.removeItem(key);
    }
  }

  getToken(): string {
    return this.TOKEN.get(this.TOKEN_KEY);
  }

  saveToken(token: string, isRemember: boolean = false): void {
    this.TOKEN.save(token, isRemember, this.TOKEN_KEY);
  }

  removeToken(): void {
    this.TOKEN.remove(this.TOKEN_KEY);
  }


  /**
   * FB TOKEN OPERATIONS
   */
  getFBToken(): string {
    return this.TOKEN.get(this.FB_TOKEN_KEY);
  }

  saveFBToken(token: string, isRemember: boolean = false): void {
    this.TOKEN.save(token, isRemember, this.FB_TOKEN_KEY);
  }

  removeFBToken(): void {
    this.TOKEN.remove(this.FB_TOKEN_KEY);
  }

  
}