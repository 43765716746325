import { ApiService } from "./api.service";
import { SaveContactUser } from "../models";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

@Injectable()
export class ContactUsService {

  constructor(
    private apiService: ApiService
  ) { }

  save(user: SaveContactUser): Observable<any> {
    return this.apiService.post('contact/save', user);
  }
}
