import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { Booking } from '../models';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(
    private apiService: ApiService
  ) { }

  getBooking(id: number): Observable<Booking> {
    return this.apiService.get(`user/getBookingById/${id}`);
  }

  createBooking(values): Observable<any> {
    return this.apiService.post('user/booking/create', values)
              .pipe(map(data => data.result));
  }

  getBookingDataByUserId(restaurant_id, user_id): Observable<any> {
    return this.apiService.post('user/booking/bookingDataByUserId', {'restaurant_id': restaurant_id, 'user_id': user_id})
              .pipe(map(data => data.result));
  }

  prepareBookingTime(restaurant_id, user_id): Observable<any> {
    return this.apiService.post('user/booking/prepareBookingTime', {'restaurant_id': restaurant_id, 'user_id': user_id});
  }

  getBookingListByUser(user_id: number): Observable<any> {
    return this.apiService.post('user/booking/bookingListByUser', {'user_id': user_id})
              .pipe(map(data => data.result));
  }

  getAllBookingListByUser(params: HttpParams): Observable<any> {
    return this.apiService.get(`user/booking/active`, params)
              .pipe(map(data => data.result));
  }

  getHisAllBookingListByUser(params: HttpParams): Observable<any> {
    return this.apiService.get(`user/booking/unactive`, params)
              .pipe(map(data => data.result));
  }

  cancelRestBooking(id: number): Observable<any> {
    return this.apiService.post('user/booking/cancel', {id : id});
  }

}
