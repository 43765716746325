import { Component } from '@angular/core';
import { AuthService } from './services';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(
    private authService: AuthService,
  ) { 
  }

  async ngOnInit() {
    this.authService.checkAuth();

    // check script url is loaded or not.
    // if (!Boolean(document.querySelector('script[src="https://bib-adsengine.com/static/js/cdn.js"]'))) {
    //   let adsConstantScript = document.createElement("script");
    //   adsConstantScript.setAttribute('type', 'text/javascript');
    //   adsConstantScript.innerHTML =
    //     'const PUBLISHER_CODE = ' + '"xzwSA5B4VuTDaHjYCtFu4aiHxeoCJOm1kya8uVI28NRd1DiMnQHW07tIHwRSmg3qb7E8Tf48hCaicaNvhnCGmwWciJXAfUAj2m1ExvkYZ4BVx0INDN0G185orIIdGq7KQvJIF218RzFsLcXrgZ1x3UNoNv3QloVCem8bw466VOao4KpkOc6NiQ2udxKCt7eFtv6BsAQddtkXWDIpkUj7HQD6lROjwvK6PmHyJlkuh9kA1PcXecPfFzUNFwqB4i2";';
    //   document.head.appendChild(adsConstantScript);

    //   let adsScript = document.createElement("script");
    //   adsScript.setAttribute(
    //     "src",
    //     "https://bib-adsengine.com/static/js/cdn.js"
    //   );
    //   document.head.appendChild(adsScript);
    // }
  }
}
