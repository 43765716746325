import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AuthService } from '../../services';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(
    public router: Router,
    public authService: AuthService
  ) { }

  canActivate(): Observable<boolean> {
    return this.authService.authCheck.pipe(first(), map(
      isAuth => {
        if (isAuth) {
          this.router.navigate(['/']);
        }
        return true;
      }
    ));
  }
}
