import { Injectable } from '@angular/core';
import { Pagination } from '../models/pagination.model';

type Pager = {
  first?: any[];
  pager?: any[];
  last?: any[];
};

@Injectable()
export class PaginatorService {
  private onEachSide = 3;
  currentPage: number;
  lastPage: number;
  perPage: number;
  url: string;

  constructor() { }

  build(pagination: Pagination) {
    this.url = pagination.url;
    this.currentPage = pagination.currentPage;
    this.lastPage = pagination.lastPage;
    this.perPage = pagination.perPage;
    return {
      paginator : this,
      pages : this.getPages()
    }
  }

  getPages():any[] {
    let pager = this.getPager();
    let pages = [
      pager.first,
      Array.isArray(pager.pager) ? '...' : null,
      pager.pager,
      Array.isArray(pager.last) ? '...' : null,
      pager.last
    ];
    return pages.filter((e) => e != null);
  }

  getCurrentPage(): number {
    return this.currentPage;
  }

  getLastPage(): number {
    return this.lastPage;
  }

  hasPages(): boolean {
    return this.lastPage > 1;
  }

  getPrevParams(): object {
    return {page: this.currentPage - 1};
  }

  getNextParams(): object {
    return {page: this.currentPage + 1};
  }

  protected getPager(): Pager {
    if (this.lastPage < (this.onEachSide * 2) + 6) {
      return this.getMiniPager();
    }
   return this.getOnePager();
  }

  private getOnePager(): Pager {
    let windows = this.onEachSide * 2;
    if (!this.hasPages()) {
      return this.getEmptyPager();
    }
    if (this.currentPage <= windows) {
      return this.getPagerCloseToStart(windows);
    } else if (this.currentPage > (this.lastPage - windows)) {
      return this.getPagerCloseToEnd(windows);
    }
    return this.getFullPager();
  }

  private getEmptyPager(): Pager {
    return {
      first : null,
      pager : null,
      last : null
    }
  }

  private getMiniPager(): Pager {
    return {
      first : this.createParamsRange(1, this.lastPage),
      pager : null,
      last: null
    }
  }
  
  private getPagerCloseToStart(windows: number): Pager {
    return {
      first : this.createParamsRange(1, windows + 2),
      pager : null,
      last : this.getFinish()
    }
  }
  
  private getPagerCloseToEnd(windows: number): Pager {
    let last = this.createParamsRange(
      this.lastPage - (windows + 2),
      this.lastPage
    )
    return {
      first : this.getStart(),
      pager : null,
      last : last
    }
  }

  private getFullPager(): Pager {
    return {
      first : this.getStart(),
      pager : this.getAdjacentRange(),
      last : this.getFinish()
    }
  }

  private getAdjacentRange() {
    return this.createParamsRange(
      this.currentPage - this.onEachSide,
      this.currentPage + this.onEachSide
    );
  }

  private getStart(): object[] {
    return this.createParamsRange(1, 2);
  }

  private getFinish(): object[] {
    return this.createParamsRange(this.lastPage - 1, this.lastPage);
  }

  private createParamsRange(start: number, end: number): object[] {
    let params = [];
    for (let index = start; index <= end; index++) {
      params.push({ page : index });
    }
    return params;
  }
}
