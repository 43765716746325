export class Booking {
  id: number;
  user_id: number;
  restaurant_id: number;
  user_name: string;
  phone_no: number;
  no_of_people: number;
  booking_date: string;
  booking_start_time: string;
  booking_end_time: string;
  created_user_id: number;
}