import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { TokenService } from './token.service';
import { ApiService } from './api.service';
import { User } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loginUserSubj = new BehaviorSubject<User | null>(null);
  public loginUser = this.loginUserSubj.asObservable().pipe(distinctUntilChanged());

  private isAuthSubj = new ReplaySubject<boolean>(1);
  public authCheck = this.isAuthSubj.asObservable();

  constructor(
    private apiService: ApiService,
    private tokenService: TokenService
  ) { }

  checkAuth() {
    const AUTH_TOKEN = this.tokenService.getToken();
    if (AUTH_TOKEN) {
      this.apiService.get('auth/authUser/check').subscribe(
        data => {
          if (data.success) {
            data.userData.token = AUTH_TOKEN
            this.setAuth(data.userData)
          } else {
            this.revokeAuth();
          }
        },
        err => this.revokeAuth()
      );
    } else {
      this.revokeAuth();
    }
  }

  attemptAuth(credentials: any, isRemember: boolean): Observable<User | any> {
    return this.apiService.post('auth/authUser', credentials).pipe(map(
      result => {
        if (result.status == 200) {
          result.userData.token = result.token
          this.tokenService.saveToken(result.token, isRemember);
          this.setAuth(result.userData);
        }
        return result;
      }
    ));
  }

  attemptFBAuth(fbToken: string): Observable<User | any> {
    let authData = { fbAuthToken: fbToken };
    return this.apiService.post('auth/authUser', authData).pipe(map(
      result => {
        if (result.status == 200) {
          result.userData.token = result.token
          this.tokenService.saveToken(result.token, true);
          this.setAuth(result.userData);
        }
        return result;
      }
    ));
  }

  revokeAuth(sendServer = true) {
    if (this.tokenService.getToken() && sendServer) {
      this.apiService.get('auth/authUser/revoke').subscribe();
    }
    this.tokenService.removeToken();
    this.loginUserSubj.next(null);
    this.isAuthSubj.next(false);
  }

  getUser(): User {
    return this.loginUserSubj.value;
  }

  updateUser(user: User): void {
    this.loginUserSubj.next(user);
  }

  setAuth(user: User) {
    this.loginUserSubj.next(user);
    this.isAuthSubj.next(true);
  }
}
