import { ApiService } from "./api.service";
import { RegisterUser, User, Password } from "../models";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

@Injectable()
export class UserService {

  constructor(
    private apiService: ApiService
  ) { }

  checkEmailIsUnique(email: string, id: number = null) {
    let data = {email: email, userId: id};
    return this.apiService.post('user/check/unique-email', data);
  }

  register(user: RegisterUser): Observable<any> {
    return this.apiService.post('user/register', user);
  }

  registerByFB(fbToken: string): Observable<any> {
    return this.apiService.post('user/register', { fbToken: fbToken});
  }

  getUserProfile(id:number): Observable<any>  {
    return this.apiService.get(`user/profile/${id}`)
              .pipe(map(data => data));
  }
  
  updateUser(user: User): Observable<User> {
    return this.apiService.post('user/profile', user);
  }

  changeUserPassword(password: Password): Observable<any> {
    return this.apiService.post('user/password_change', password);
  }
}
