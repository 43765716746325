export class Pagination {
  currentPage: number;
  lastPage: number;
  perPage: number;
  url: any;

  constructor(
    currentPage: number,
    lastPage: number,
    perPage: number,
    url: any
  ) {
    this.currentPage = currentPage;
    this.lastPage = lastPage;
    this.perPage = perPage;
    this.url = url;
  }
}