import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { Restaurant } from '../models';
import { map } from 'rxjs/operators';

@Injectable()
export class RestaurantService {

  constructor(
    private apiService: ApiService
  ) { }

  getRestaurant(id: number, user_id: number): Observable<Restaurant> {
    return this.apiService.get(`admin/getRestaurantById/${id}/${user_id}`);
  }

  getImagesList(id: number): Observable<any> {
    return this.apiService.get(`user/restaurant-images/${id}`);
  }

  getRstTypeList(): Observable<any> {
    return this.apiService.get('admin/getAllRstType');
  }

  getCityList(): Observable<any> {
    return this.apiService.get('admin/getAllCity');
  }

  getTownshipList(): Observable<any> {
    return this.apiService.get('admin/getAllTownship');
  }

  getFoodTypes(): Observable<any> {
    return this.apiService.get('admin/getAllFoodTypes');
  }

  getMenuList(id: number): Observable<any> {
    return this.apiService.get(`user/restaurant-menu/${id}`);
  }

  prepareSearch(): Observable<any> {
    return this.apiService.get('user/restaurant/prepare-search');
  }

  getPopularRestaurants(): Observable<any> {
    return this.apiService.get('user/restaurant/popular');
  }

  searchRestaurants(params: HttpParams): Observable<any> {
    return this.apiService.get('user/restaurant/search', params);
  }

  getAllRestListByUser(params: HttpParams): Observable<any> {
    return this.apiService.get('user/bookmark/all_restaurantlist', params)
              .pipe(map(data => data));
  }

  bookmarkRestaurant(id, user_id): Observable<any> {
    return this.apiService.post('user/restaurant/bookmark', { 'restaurant_id': id, 'user_id': user_id });
  }

  removeBookmarkRestaurant(id, user_id): Observable<any> {
    return this.apiService.post('user/restaurant/removeBookmark', { 'restaurant_id': id, 'user_id': user_id });
  }

  deleteBookmarkRestaurant(id, user_id): Observable<any> {
    return this.apiService.post('user/restaurant/deleteBookmark', { 'restaurant_id': id, 'user_id': user_id });
  }

  getBookmarkListByUser(user_id: number): Observable<any> {
    return this.apiService.post('user/restaurant/bookmarkListByUser', { 'user_id': user_id })
                          .pipe(map(data => data.result));
  }
}
