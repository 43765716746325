import { Component, Input } from '@angular/core';

@Component({
  selector: 'pro-bar',
  templateUrl: './pro-bar.component.html',
  styleUrls: ['./pro-bar.component.css']
})
export class ProBarComponent {
  @Input() value: number = 0;
  @Input() max: number = 100;

  constructor() { }

  get current() {
    if (this.value == 0) return;
    return Math.round((this.value / this.max) * 100);
  }

}
