import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../../services';
import { User } from '../../../models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  @Output() pwChangeEvent = new EventEmitter<boolean>();
  @Input() onlyLogo: boolean = false;
  isAuth: boolean;
  isReady: boolean = false;
  changePW: boolean;

  constructor(
    private authService: AuthService,
    private route: Router,
  ) {
    this.authService.authCheck.subscribe(
      isAuth => {
        this.isAuth = isAuth;
        this.isReady = true;
      }
    );
  }

  get authUser(): User {
    return this.authService.getUser();
  }

  logout() {
    this.authService.revokeAuth();
    if (this.route.url != '/' && this.route.url != '/search' && this.route.url.substr(0, 12) != '/restaurant/') {
      this.route.navigate(['/login']);
    }
  }

  showPWChange() {
    this.changePW = true;
    this.pwChangeEvent.emit(this.changePW);
  }

  hidePWChange() {
    this.changePW = false;
  }

}
