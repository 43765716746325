import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SearchParams } from '../models';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private paramsSource = new BehaviorSubject<SearchParams>({} as SearchParams);
  public searchParams = this.paramsSource.asObservable();

  addParams(params: SearchParams) {
    this.paramsSource.next(params)
  }

  getParams(): SearchParams {
    let value = this.paramsSource.value;
    this.paramsSource.next({} as SearchParams);
    return value;
  }
}