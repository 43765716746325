import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
// import { AuthService } from 'angularx-social-login';
// import { SocialUser } from 'angularx-social-login';
// import { FacebookLoginProvider } from 'angularx-social-login';

@Injectable()
export class LoginService {

  constructor(
    private apiService: ApiService
  ) { }
  
  authenticateAdmin(values): Observable<any>  {
    return this.apiService.post("auth/authAdmin", values);
  }

  authenticateUser(values): Observable<any>  {
    return this.apiService.post("auth/authUser", values);      
  }

  sendResetPasswordRequest(values) {
    return new Promise((resolve, reject) => {
      this.apiService.post("auth/sendResetPasswordRequest", values)
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }
  
  resetPassword(verification_code, url_id, password) {
    return new Promise((resolve, reject) => {
      this.apiService.post("auth/resetPassword", {
        authentication_code: verification_code,
        url_id: url_id,
        password: password
      })
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  // loginWithFacebook() {
  //   return new Promise((resolve, reject) => {
  //     this.auth.signIn(FacebookLoginProvider.PROVIDER_ID)
  //       .then((user: SocialUser) => {
  //         console.log(user);
          
  //         this.apiService.post("auth/authUserByFacebook", user)
  //           .subscribe(res => {
  //             console.log(res);
  //             resolve(res.json());
  //           }, (err) => {
  //             reject(err);
  //           });
  //       })
  //       .catch(err => {
  //         reject(err);
  //       })
  //   });
  // }
}
