import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService} from '../../../../services/login.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-resetpasswordrequest',
  templateUrl: './resetpasswordrequest.component.html',
  styleUrls: ['./resetpasswordrequest.component.css']
})
export class ResetpasswordrequestComponent implements OnInit {
  resetPwdReqForm: FormGroup;
  email: any[];
  submitted: boolean = false;
  errorMessage: string;
  successMessage: string;
  sent_status: boolean;
  loading: boolean = false;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private location: Location
  ) {
    this.resetPwdReqForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      type: [1]
    });
   }

  ngOnInit() {
  }

  get resetPwdReqValidator() {    
    return this.resetPwdReqForm.controls;
  }

  getResetEmail() {
    let values = this.resetPwdReqForm.value;
    this.resetpasswordrequest(values);
  }
  
  resetpasswordrequest(values) {
    this.submitted = true;
    if (this.resetPwdReqForm.invalid) {
      return false;
    }
    this.loading = true;
    this.loginService.sendResetPasswordRequest(values)
      .then((res: any) => {
        if (res.status == 404) {
          this.loading = false;
          return this.errorMessage = "User not found!";
      }
        if (res.status == 200) {
          this.loading = false;
          this.sent_status = true;
          return this.email = values.email;
        }
      })
      .catch(err => console.log(err))
  }

  goBack(): void {
    this.location.back();
  }
}
