import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable()
export class FeedbackService {

  constructor(
    private apiService: ApiService
  ) { }

  getFeedbackMsg(restaurantId: number): Observable<any> {
    return this.apiService.get(`user/feedback/get_by_restaurant/${restaurantId}`);
  }

  getUserFeedback(restaurantId: number): Observable<any> {
    return this.apiService.post('user/feedback/get', {restaurant_id: restaurantId}).pipe(map(data => data.feedback));
  }

  submitFeedback(data): Observable<any> {
    return this.apiService.post(`user/feedback/save`, data);
  }
}
